<template>
<div>
  <b-card style="max-width: 900px">
    <h4 class="mb-1">Two-Factor Authentication</h4>
Add an extra layer of security to your Brime account by using your password and a code on your mobile phone to log in.
    <hr>
  <!-- primary -->
    <b-button variant="primary" @click="reqestTicket()" v-if="!twofactorEnabled">Enroll in 2FA</b-button>
    <div v-if="twofactorEnabled">
    <strong>Status:</strong> <b-badge pill variant="success">2-Factor Enabled</b-badge>
    <div style="margin-top: 5px;"><strong>Date Enabled:</strong> {{enabled_date}}</div>
    <div style="margin-top: 5px;"><strong>Last Auth:</strong> {{last_auth}}</div>
    <div class="mt-2">If you need to reset or remove 2-factor authentication on your account. <br>Please open a support ticket.</div>
    </div>

    
  </b-card>
</div>
</template>

<script>
import { BCard, BButton, BButtonGroup, BFormCheckbox, BBadge  } from 'bootstrap-vue'
import { data } from 'vue-echarts'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
components: {
BCard,
BButton, 
BButtonGroup,
BFormCheckbox,
ToastificationContent,
BBadge 
},
data() {
return {
    twofactorEnabled: false,
    factors: [],
    enabled_date: ''
}
},
async mounted() {
    let result = await this.list2FA()
    if (result.factors.length > 0) {
        var newArray = result.factors.filter(function (el) {
        return el.confirmed == true && el.type !== 'email' && el.type !== 'recovery-code'
});
        this.factors = newArray
        if(this.factors.length > 0) {
            this.twofactorEnabled = true
            var d = new Date(this.factors[0].created_at);
            this.enabled_date = d.toDateString();
            var d2 = new Date(this.factors[0].last_auth_at);
            this.last_auth = d2.toDateString();
        }
    }

},
methods: {
async reqestTicket() {
    var ticketRequest = await this.enroll2FA()
    window.open(ticketRequest.ticket_url, '_blank')
    setInterval(async() => {
         let result = await this.list2FA()
    if (result.factors.length > 0) {
        var newArray = result.factors.filter(function (el) {
        return el.confirmed == true && el.type !== 'email' && el.type !== 'recovery-code'
});
        this.factors = newArray
        if(this.factors.length > 0) {
            this.twofactorEnabled = true
            var d = new Date(this.factors[0].created_at);
            this.enabled_date = d.toDateString();
            var d2 = new Date(this.factors[0].last_auth_at);
            this.last_auth = d2.toDateString();
        }
    }
    }, 5000)
}
}
}
</script>

<style>
.connectButton {
  float: right;
  display: block;
  justify-content: right;
  align-items: right;
  transform: translateY(140%)
}
.slobs {
  float: left;
  display: block;
  justify-content: right;
  align-items: right;
}
</style>