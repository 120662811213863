<!-- Vue component -->
<template>
  <div>
    <b-card style="max-width: 50em;" border-variant="primary"
      ><b-card-text class="mt-0 mb-0">
        <h3 style="color: #7367f0">
          Chat Settings
        </h3>
        <hr class="" />
      </b-card-text>
      <h6>Chat Username Color</h6>
      <v-swatches v-model="color" :swatches="swatches" @input="ChatColor" />
      <b-form-group class="mt-2">
        <h6>Chat Language</h6>
        <p>This feature translates messages into your native language. <br>For the first time ever, chat can truly be global. <feather-icon
                icon="HeartIcon"
                size="16"
                style="margin-top: -5px;"
              /></p>
        <v-select
          v-model="selected"
          @input="ChatLang"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="language"
          :options="option"
          style="max-width: 300px;"
        />
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
import VSwatches from "vue-swatches";
import {
  BCard,
  BCardText,
  BFormGroup, // BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";

// Import the styles too, typically in App.vue or main.js
import "vue-swatches/dist/vue-swatches.css";

export default {
  components: {
    BFormGroup,
    VSwatches,
    BCard,
    BCardText,
    vSelect,
  },
  data() {
    return {
      selected: '',
      option: [
{"language": "Bulgarian", "code": "BG"},
{"language": "Chinese", "code": "ZH"},
{"language": "Czech", "code": "CS"},
{"language": "Danish", "code": "DA"},
{"language": "Dutch", "code": "NL"},
{"language": "English (American)", "code": "EN-US"},
{"language": "English (British)", "code": "EN-GB"},
{"language": "Estonian", "code": "ET"},
{"language": "Finnish", "code": "FI"},
{"language": "French", "code": "FR"},
{"language": "German", "code": "DE"},
{"language": "Greek", "code": "EL"},
{"language": "Hungarian", "code": "HU"},
{"language": "Italian", "code": "IT"},
{"language": "Japanese", "code": "JA"},
{"language": "Latvian", "code": "LV"},
{"language": "Lithuanian", "code": "LT"},
{"language": "Polish", "code": "PL"},
{"language": "Portuguese (Brazilian)", "code": "PT-BR"},
{"language": "Portuguese", "code": "PT-PT"},
{"language": "Romanian", "code": "RO"},
{"language": "Russian", "code": "RU"},
{"language": "Slovak", "code": "SK"},
{"language": "Slovenian", "code": "SL"},
{"language": "Spanish", "code": "ES"},
{"language": "Swedish", "code": "SV"}
],
      color: "#1CA085",
      swatches: [
        "#1FBC9C",
        "#1CA085",
        "#2ECC70",
        "#27AF60",
        "#3398DB",
        "#2980B9",
        "#A463BF",
        "#8E43AD",
        "#3D556E",
        "#FF10F0",
        "#F2C511",
        "#F39C19",
        "#E84B3C",
        "#C0382B",
        "#DDE6E8",
        "#BDC3C8",
      ],
    };
  },
  async created() {
    const {chat_color, chat_lang} = await this.getUser();
    console.log(chat_color, chat_lang);
    this.color = chat_color
    const languages = [
{"language": "Bulgarian", "code": "BG"},
{"language": "Chinese", "code": "ZH"},
{"language": "Czech", "code": "CS"},
{"language": "Danish", "code": "DA"},
{"language": "Dutch", "code": "NL"},
{"language": "English (American)", "code": "EN-US"},
{"language": "English (British)", "code": "EN-GB"},
{"language": "Estonian", "code": "ET"},
{"language": "Finnish", "code": "FI"},
{"language": "French", "code": "FR"},
{"language": "German", "code": "DE"},
{"language": "Greek", "code": "EL"},
{"language": "Hungarian", "code": "HU"},
{"language": "Italian", "code": "IT"},
{"language": "Japanese", "code": "JA"},
{"language": "Latvian", "code": "LV"},
{"language": "Lithuanian", "code": "LT"},
{"language": "Polish", "code": "PL"},
{"language": "Portuguese (Brazilian)", "code": "PT-BR"},
{"language": "Portuguese", "code": "PT-PT"},
{"language": "Romanian", "code": "RO"},
{"language": "Russian", "code": "RU"},
{"language": "Slovak", "code": "SK"},
{"language": "Slovenian", "code": "SL"},
{"language": "Spanish", "code": "ES"},
{"language": "Swedish", "code": "SV"}
]
    const result = languages.find( ({ code }) => code === chat_lang.toUpperCase() );
    this.selected = result.language;
  },
  methods: {
    async ChatColor() {
      await this.updateChatColor(this.color);
      this.$bvToast.toast("Chat color updated.", {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },
    async ChatLang() {
      await this.updateChatLang(this.selected.code);
      this.$bvToast.toast("Chat language updated.", {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },
  },
};
</script>
<style>
.vue-swatches__container {
  background-color: #111727 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
