<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >

    <!-- info -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Profile</span>
      </template>

      <account-profile />
    </b-tab>
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="CheckSquareIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Preferences</span>
      </template>

      <preferences />
    </b-tab>
    <b-tab disabled>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ToolIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Connections</span>
      </template>
    </b-tab>
    <b-tab disabled>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="MessageSquareIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Alerts & Notifications</span>
      </template>
    </b-tab>
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Security & Privacy</span>
      </template>
      <Security />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountProfile from './AccountProfile.vue'
// import AccountSettingSocial from './AccountSettingSocial.vue'
// import AccountChannelSettings from './AccountChannelSettings.vue'
// import GettingStarted from './GettingStarted.vue'
// import Monetization from './Monetization.vue'
// import Multistreaming from './Multistreaming.vue'
// import Emotes from './Emotes.vue'
// import AccountSettingNotification from './AccountSettingNotification.vue'
import Preferences from './Preferences.vue'
import Security from './Security.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountProfile,
    Preferences,
    Security
    // AccountSettingInformation,
    // AccountSettingSocial,
    // AccountChannelSettings,
    // GettingStarted,
    // Monetization,
    // Multistreaming,
    // Emotes,
  },
  data() {
    return {
      creatorStatus: '',
      userData: '',
      monetized: false,
      options: {},
    }
  },
  // eslint-disable-next-line no-empty-function
  async mounted() {
  },
  async beforeCreate() {
    // this.userData = await window.brime.getUser()
    // if (await this.userData.creatorStatus === 'ELITE') {
    //   this.monetized = true
    // }
  },
}
</script>
