<template>
  <div>
    <b-card
      style="max-width: 40em;"
      border-variant="primary"
    >
      <h3 style="color: #7367f0">
        Display Name
      </h3>
      <hr class="">
      Customize capitalization for your username<br><br>
      <!-- form -->
      <b-form>
        <b-row>
          <!-- bio -->
          <b-col cols="6">
            <b-form-group
              label-for="bio-area"
              style="max-width: 20em;"
            >
              <b-form-input
                id="displayName"
                v-model="displayname"
                placeholder="BrimerJones"
                rows="1"
                maxlength="80"
              />
            </b-form-group>
          </b-col>
          <!--/ bio -->
          <!--/ website -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mt-1 mr-1"
              @click.prevent="updateDisplayName(displayname)"
            >
              Save changes
            </b-button>
          </b-col>
        </b-row>
      </b-form></b-card>
    <b-card
      style="max-width: 40em;"
      border-variant="primary"
    >
      <b-form>
        <h3 style="color: #7367f0">
          User Avatar
        </h3>
        <hr class="">
        <b-row>
          <center>
            <b-col cols="7">
              <b-avatar
                size="150"
                :src="avatarImageUrl"
                variant="light-primary"
                class="badge-minimal mb-2"
              />
              <b-form-file
                id="avatarUpload"
                ref="avatarUpload"
                style="max-width: 30em;"
                accept="image/jpeg, image/png, image/gif"
                @change="updateAvatarImage"
              />
            </b-col><p class="mt-1">
                Max File Size: 10mb | GIFs are supported.
              </p></center>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BCard, BFormFile, BAvatar, BFormInput, BButton, BFormGroup, // BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    BFormFile,
    BAvatar,
    BFormInput,
    BButton,
    BFormGroup,
    // BCardText,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userAvatar: null,
      offlineImageUrl: '',
      avatarImageUrl: '',
      userData: { displayName: '' },
      displayname: '',
    }
  },
  async created() {
    this.userData = await this.getUser()
    this.avatarImageUrl = `https://assets.brimecdn.com/users/${this.userData.xid}/avatar`
    this.displayname = this.userData.display_name
  },
  async mounted() {

    // eslint-disable-next-line no-undef
    // eslint-disable-next-line no-undef
    // const { offlineImageUrl: url } = await window.brime.getChannel()
    // const { avatar } = await window.brime.getUser()
    // this.userData = await window.brime.getUser()
    // this.offlineImageUrl = url
    // this.avatarImageUrl = avatar
  },
  methods: {
    makeToast(message) {
      this.$bvToast.toast(message, {
        title: 'Error uploading image.',
        variant: 'danger',
        solid: true,
      })
    },
    async updateDisplayName(displayName) {
      const result = await this.userSetDisplayname(displayName)
      if (!result.error) {
        this.$bvToast.toast('Display Name Updated!', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } else {
        this.$bvToast.toast('Display name changes must only be to the capitalization.', {
          title: 'Error.',
          variant: 'danger',
          solid: true,
        })
      }
    },
    updateChannelOfflineImage() {
      window.brime.updateChannelOfflineImage(this.$refs.imageUpload.$refs.input)
        .then(url => {
          this.$bvToast.toast('Channel Offline Image Updated', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
          this.offlineImageUrl = `${url}?${Date.now()}`
        })
        .catch(errors => {
          const message = errors[0].split(':')[1]
          this.$bvToast.toast(message, {
            title: 'Error.',
            variant: 'danger',
            solid: true,
          })
        })
    },
    async updateAvatarImage() {
      const result = await this.uploadUserAvatar(this.$refs.avatarUpload.$refs.input.files[0])
      if (!result.error) {
        this.$bvToast.toast('Avatar Image Updated', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
        this.avatarImageUrl = `${result.url}?date=${Date.now()}`
      } else {
        this.$bvToast.toast('Error', {
          title: 'Error.',
          variant: 'danger',
          solid: true,
        })
      }
    },
  },
}
</script>
<style>
.form-file-text{
color: #283046;
}
#customQuill {
  min-height: 375px;
  overflow: auto;
}

/* Custom Rules for the Snow Theme */

.ql-toolbar.ql-snow{
  background-color: #111727;
  border: 1px solid #7367f0;
}

.ql-container.ql-snow{
  background-color: #161d31;
  border: 1px solid #7367f0;
  color:#7367f0;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #fff;
}

.ql-snow .ql-picker{
  color:#fff;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #fff;
}
.ql-snow .ql-toolbar.snow, .ql-snow .ql-stroke{
  stroke:#fff;
}
.ql-picker-options{
background-color:#111727;
}

ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color:#7367f0;
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #7367f0;
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter {
  stroke: #7367f0;
}

.ql-editor.ql-blank::before {
  color: #7367f0
}
.aboutEditor{
margin-top: 5vh;
}
</style>
